import axios from 'axios';
import APIUtils from './APIUtils';
import LocaleUtils, { LanguageObject } from './LocaleUtils';

interface Community {
  id: number;
  name: LanguageObject;
  deployment: boolean;
}

const community = {
  generateDefault: (item?: Partial<Community>): Partial<Community> => {
    return {
      id: item?.id,
      name: LocaleUtils.langobj.generate(item?.name),
      deployment: item?.deployment,
    };
  },
};

const api = {
  list: async (params?: { visible: boolean }) => {
    const resp = await axios.get<Community[]>('community/list.php', {
      params: params,
    });
    return resp.data;
  },
  get: async (id: number) => {
    const resp = await axios.get<Community>('community/get.php', {
      params: { id: id },
    });
    return resp.data;
  },
  create: async (item: Partial<Community>) => {
    const resp = await axios.post<Community>('community/create.php', {
      ...APIUtils.clean(item),
    });
    return resp.data;
  },
  update: async (item: Partial<Community>) => {
    const resp = await axios.put<Community>('community/update.php', {
      ...APIUtils.clean(item),
    });
    return resp.data;
  },
  remove: async (id: number) => {
    const resp = await axios.delete<Community>('community/remove.php', {
      params: { id: id },
    });
    return resp.data;
  },
};

export default { api, community };
export { Community };
