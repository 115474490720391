
























import Vue from 'vue';
import CommunityUtils, { Community } from '@/utils/CommunityUtils';
import { DataTableHeader } from 'vuetify';

export default Vue.extend({
  computed: {
    headers(): DataTableHeader<Community>[] {
      return [
        {
          text: this.$tc('community.name'),
          value: 'name',
        },
        {
          text: '',
          value: 'actions',
        },
      ];
    },
  },

  data: (): {
    items?: Community[];
  } => ({
    items: undefined,
  }),

  async mounted() {
    try {
      this.items = undefined;
      this.items = await CommunityUtils.api.list();
    } catch (error) {
      console.error(error);
    } finally {
      //
    }
  },

  methods: {
    async handleEdit(item: Community) {
      await this.$router.push({
        name: 'community-edit',
        params: { id: item.id?.toString() },
      });
    },

    async handleCreate() {
      await this.$router.push({
        name: 'community-create',
      });
    },
  },
});
